// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  afterLoginPath: '/admin',
  baseUrl: 'https://api-dev.payee.tech/admin',
  titleSuffix: '(Testing)',
  paymentPayeeURL: 'https://dev-payment-payee.web.app/',

  // pwc
  PWC: true,
  pwcAuthUrl:
    'https://login-stg.pwc.com/openam/oauth2/authorize?'
    + 'client_id=urn:payee_adm_stg'
    + '&response_type=code'
    + '&scope=openid+profile',

  ARROW_DOWN_ICON: 'pwc-arrow-down',
  COPY_ICON: 'pwc-copy',
  EXTERNAL_LINK_ICON: 'pwc-external-link',
  MORE_ICON: 'pwc-more',
  PLUS_ICON: 'pwc-plus',
  RADIO_CHECKED_ICON: 'pwc-radio-checked',
  UPLOAD_CLOUD_ICON: 'pwc-upload-cloud',
  CHECKBOX_FILLED_ICON: 'pwc-checkbox-filled',
  CHECKMARK_DARKER_GREEN_CIRCLE_ICON: 'pwc-checkmark-darker-green-circle',
  CHECKMARK_GREEN_CIRCLE_ICON: 'pwc-checkmark-green-circle',
  TOGGLE_ON_ICON: 'pwc-toggle-on',
  TRASH_ICON: 'pwc-trash',
  LOGO_ICON: 'pwc-logo',

  HELP_URL: 'https://www.pwc.com/hu/hu/rolunk.html',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
