export type EnumDescriptorColumn = {
  name: string;
  type: 'int' | 'string' | 'enum' | 'array' | 'boolean' | 'external';
  values?: string[];
  externalModel?: string;
  readonly: boolean;
};

export type EnumDescriptor = {
  model: string;
  columns: EnumDescriptorColumn[];
};

export const claimType: EnumDescriptor = {
  model: 'App\\Models\\ClaimType',
  columns: [{
    name: 'id',
    type: 'int',
    readonly: true,
  }, {
    name: 'internal_id',
    type: 'string',
    readonly: true,
  }, {
    name: 'label',
    type: 'string',
    readonly: false,
  }, {
    name: 'code',
    type: 'int',
    readonly: false,
  }, {
    name: 'name',
    type: 'string',
    readonly: false,
  }],
};

export const legalRelationship: EnumDescriptor = {
  model: 'App\\Models\\LegalRelationship',
  columns: [{
    name: 'id',
    type: 'int',
    readonly: true,
  }, {
    name: 'name',
    type: 'string',
    readonly: false,
  }, {
    name: 'label',
    type: 'int',
    readonly: false,
  }, {
    name: 'code',
    type: 'string',
    readonly: false,
  }],
};

export const partyType: EnumDescriptor = {
  model: 'App\\Models\\PartyType',
  columns: [{
    name: 'id',
    type: 'int',
    readonly: true,
  }, {
    name: 'internal_id',
    type: 'string',
    readonly: true,
  }, {
    name: 'type',
    type: 'enum',
    readonly: false,
    values: [
      'org',
      'ind',
    ],
  }, {
    name: 'registration_office_id',
    type: 'external',
    readonly: false,
    externalModel: 'App\\Models\\RegistrationOffice',
  }, {
    name: 'name',
    type: 'string',
    readonly: false,
  }, {
    name: 'label',
    type: 'int',
    readonly: false,
  }, {
    name: 'match_reference',
    type: 'array',
    readonly: false,
  }],
};

export const representativeType: EnumDescriptor = {
  model: 'App\\Models\\RepresentativeType',
  columns: [{
    name: 'id',
    type: 'int',
    readonly: true,
  }, {
    name: 'code',
    type: 'string',
    readonly: false,
  }, {
    name: 'is_agent',
    type: 'boolean',
    readonly: false,
  }, {
    name: 'name',
    type: 'string',
    readonly: false,
  }, {
    name: 'label',
    type: 'int',
    readonly: false,
  }, {
    name: 'type',
    type: 'enum',
    readonly: false,
    values: [
      'org',
      'ind',
    ],
  }, {
    name: 'match_reference',
    type: 'array',
    readonly: false,
  }],
};

export const registrationOffice: EnumDescriptor = {
  model: 'App\\Models\\RegistrationOffice',
  columns: [{
    name: 'id',
    type: 'int',
    readonly: true,
  }, {
    name: 'internal_id',
    type: 'string',
    readonly: true,
  }, {
    name: 'name',
    type: 'string',
    readonly: false,
  }, {
    name: 'label',
    type: 'int',
    readonly: false,
  }, {
    name: 'country_iso',
    type: 'enum',
    readonly: false,
    values: [
      'HU',
    ],
  }],
};

export const enumDescriptors = [
  claimType,
  legalRelationship,
  partyType,
  representativeType,
  registrationOffice,
];
