<div class="wrapper flex column" [class.close]="!open">
  <div class="logo-box flex hcenter vcenter">
    <img
      *ngIf="open"
      class="logo"
      [src]="
        environment.PWC
          ? 'assets/shared/global-images/partner-logos/pwc-white-outlined.png'
          : 'assets/shared/global-images/payee-logos/v1/white-payee-white-dot.svg'
      "
      width="100px"
      routerLink="dashboard"
    />
    <img
      *ngIf="!open"
      class="logo"
      [src]="
        environment.PWC
          ? 'assets/shared/global-images/partner-logos/pwc-white-outlined.png'
          : 'assets/shared/global-images/payee-logos/v1/payee-webclip.svg'
      "
      width="56.83px"
      routerLink="dashboard"
    />
  </div>

  <div
    *ngIf="showProductPricePage"
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="product-prices"
    [matTooltip]="open ? '' : 'Termékek'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">Termékek</span>
  </div>
  <div
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="cases-list"
    [matTooltip]="open ? '' : 'Ügyek'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">Ügyek</span>
  </div>
  <div
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="clients-list"
    [matTooltip]="open ? '' : 'Hitelezők'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">Hitelezők</span>
  </div>
  <div
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="roles"
    [matTooltip]="open ? '' : 'Jogosultságok'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="arrow-right-grey"></payee-icon>
    <span [class.close]="!open">Jogosultságok</span>
  </div>
  <div class="logout-divider"></div>
  <div
    class="menu-button flex vcenter"
    (click)="logout()"
    [matTooltip]="open ? '' : 'Kijelentkezés'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <payee-icon name="logout-red"></payee-icon>
    <span [class.close]="!open" color="alert-red">Kijelentkezés</span>
  </div>
  <div class="grow3"></div>
  <!--<div [class.close]="!open" class="payee-email">hello@payee.tech</div>-->
  <div [class.close]="!open" class="version">
    Verziószám: <strong #appVersion>v{{ versionNumber }}</strong>
  </div>
  <button
    [class.close]="!open"
    mat-button
    class="toggle-button"
    (click)="toggleOpen()"
  >
    <img
      *ngIf="open"
      src="assets/shared/global-images/icons/arrow-double-left-grey.svg"
    />
    <img
      *ngIf="!open"
      src="assets/shared/global-images/icons/arrow-double-right-grey.svg"
    />
  </button>
</div>
