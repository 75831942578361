import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import axios from 'axios';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users/users.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';

export type TemplateOption = {
  class: string;
  language: string;
  subject: string;
};

@Component({
  selector: 'app-workflow-email-test',
  templateUrl: './workflow-email-test.component.html',
  styleUrls: ['./workflow-email-test.component.scss']
})
export class WorkflowEmailTestComponent implements OnInit, OnDestroy {
  readonly formGroup: FormGroup;
  userName?: string;
  templateOptions: TemplateOption[] = [];

  private readonly destroy = new Subject<void>();

  get templates(): FormArray { return this.formGroup.get('templates') as FormArray; }
  get payee_case_id(): FormControl { return this.formGroup.get('payee_case_id') as FormControl; }

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private fb: FormBuilder,
  ) {
    this.formGroup = this.fb.group({
      templates: this.fb.array([], Validators.required),
      payee_case_id: ['P1001', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.usersService.userSubject
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: user => {
          const parts = user?.name?.split(' ', 2);
          this.userName = parts?.map(p => p[0])?.join('');
        },
      });

    const result = await axios.get<TemplateOption[]>(environment.baseUrl + '/api/test-email/workflow-templates');
    this.templateOptions = result.data;
    this.templateOptions
      .filter(t => /.*ASoftEmailTov2.*Debtor$/.test(t.class))
      .forEach(t => this.templates.push(this.fb.control(t)));
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  getIndexOfTemplate(t: TemplateOption): number {
    return this.templates.value.findIndex((selected: TemplateOption) => t.class === selected.class);
  }

  togglaAll(): void {
    if (this.templates.length !== this.templateOptions.length) {
      this.templateOptions.forEach(t => {
        const index = this.getIndexOfTemplate(t);
        if (index === -1) {
          this.templates.push(this.fb.control(t));
        }
      });
    } else {
      this.templates.clear();
    }
  }

  onTemplateChanged(event: any, t: TemplateOption) {
    const { checkboxState } = event;
    const index = this.getIndexOfTemplate(t);
    if (index === -1 && checkboxState) {
      this.templates.push(this.fb.control(t));
    } else if (index !== -1 && !checkboxState) {
      this.templates.removeAt(index);
    }
  }

  async submit(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }

    await axios.post(environment.baseUrl + '/api/test-email/workflow-templates', {
      payee_case_reference_id: this.payee_case_id.value,
      templates: this.templates.value.map((t: TemplateOption) => t.class),
    });
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }
}
