import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CasesTableComponent } from './cases-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CaseEventsModule } from 'src/app/shared/case-events/cases-events.module';
import { CaseDetailsModule } from 'src/app/shared/case-details/cases-details.module';



@NgModule({
  declarations: [
    CasesTableComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatMenuModule,
    PayeeIconModule,
    MatTabsModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    PayeeButtonsModule,
    CaseEventsModule,
    CaseDetailsModule,
  ],
  exports: [
    CasesTableComponent,
  ],
})
export class CasesTableModule { }
