import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientDataTableItem, ClientsService } from 'src/app/services/clients/clients.service';

@Component({
  selector: 'app-delete-client-dialog',
  templateUrl: './delete-client-dialog.component.html',
  styleUrls: ['./delete-client-dialog.component.scss']
})
export class DeleteClientDialogComponent {
  submitLoading = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public client: ClientDataTableItem,
    private clientsService: ClientsService,
    private snackbar: MatSnackBar,
  ) { }

  async submit() {
    if (this.submitLoading) {
      return;
    }

    try {
      this.submitLoading = true;

      await this.clientsService.deleteClient(this.client.id);

      this.dialogRef.close(true);
    } catch (error) {
      console.error('Error while deleting client', error);
      this.snackbar.open('Valami hiba történt!', 'OK', {
        duration: 5000,
      });
      this.dialogRef.close();
    } finally {
      this.submitLoading = false;
    }
  }

}
