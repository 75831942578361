import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { CasesService, CasesTableData } from 'src/app/services/cases/cases.service';
import { ClientsService, ListClientsResponse } from 'src/app/services/clients/clients.service';
import { DebtorsService } from 'src/app/services/debtors/debtors.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cases',
  templateUrl: './cases-list.component.html',
  styleUrls: ['./cases-list.component.scss']
})
export class CasesListComponent implements OnInit, OnDestroy {
  selectorForm: FormGroup;
  isDevModeActive = false;

  private readonly subKiller = new Subject<void>();

  clients: ListClientsResponse['clients'] = [];
  selectedClient?: ListClientsResponse['clients'][number];

  userName: string;

  debtorNames: string[] = [];
  selectedDebtor?: string;

  tableData: CasesTableData[] = [];

  pageSize: number = 10;
  pageIndex: number = 0;
  length: number = 0;

  loading = false;
  casesLoading = false;

  get caseFilter(): FormControl { return this.selectorForm.get('caseFilter') as FormControl; }
  get clientFilter(): FormControl { return this.selectorForm.get('clientFilter') as FormControl; }
  get debtorFilter(): FormControl { return this.selectorForm.get('debtorFilter') as FormControl; }

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private casesService: CasesService,
    private clientsService: ClientsService,
    private debtorsService: DebtorsService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
  ) {
    this.selectorForm = this.fb.group({
      caseFilter: '',
      clientFilter: '',
      debtorFilter: '',
    });
  }

  async ngOnInit() {
    this.setClientFilterFromQueryParams();
    this.updateCasesAfterCaseFilterChanges();
    this.updateCasesAfterDebtorFilterChanges();
    this.updateCasesAfterClientFilterChanges();

    await Promise.allSettled([
      this.setCases(),
      this.getUser(),
    ]);
  }

  ngOnDestroy(): void {
    this.subKiller.next();
    this.subKiller.complete();
  }

  readonly displayWith = <T extends { name: string; }>(e: T) => e.name;
  readonly filterPredicate = <T extends { name: string; }>(e: T, filter: string) => e.name.toLowerCase().includes(filter.toLowerCase());

  readonly clientSelected = async (client?: ListClientsResponse['clients'][number]) => {
    if (client?.id === this.selectedClient?.id) {
      return;
    }
    this.selectedClient = client;
    this.pageIndex = 0;

    await this.setCases();
  };

  readonly debtorSelected = async (debtor?: string) => {
    if (debtor === this.selectedDebtor) {
      return;
    }
    this.selectedDebtor = debtor;
    this.pageIndex = 0;

    await this.setCases();
  };

  async pageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    await this.setCases();
  }

  async logout() {
    await this.authService.logout();
  }

  filterCase(id: string): void {
    this.caseFilter.patchValue(id);
  }

  private async getUser(): Promise<void> {
    try {
      const user = await this.usersService.getUser();
      this.userName = user.name;
    } catch (error) {
      console.error('Failed to get user data', error);
    }
  }

  private async setCases(): Promise<void> {
    if (this.casesLoading) {
      return;
    }

    try {
      this.casesLoading = true;

      let page = this.pageIndex + 1;

      const payee_case_id = this.caseFilter.value || undefined;

      const result = await this.casesService.getCases({
        per_page: this.pageSize,
        page,
        client_id: this.selectedClient?.id,
        debtor_name: this.selectedDebtor,
        payee_case_id,
      });

      this.tableData = result.data;
      this.length = result.pagination.total;
      this.pageIndex = result.pagination.currentPage - 1;
      this.pageSize = result.pagination.perPage;
    } catch (error) {
      console.error('Failed to get cases', error);
      this.snackbar.open('Valami hiba történt az ügyek betöltésekor!', 'OK', {
        duration: 5000,
      });
    } finally {
      this.casesLoading = false;
    }
  }

  private setClientFilterFromQueryParams(): void {
    const clientId = this.route.snapshot.queryParams.clientId;
    if (!clientId) {
      return;
    }

    this.selectedClient = {
      id: clientId,
      name: '',
    };

    this.clientsService.getClient(clientId)
      .then(client => {
        if (!client) {
          return;
        }
        this.clients = [client];
        this.selectedClient = client;
        this.clientFilter.patchValue(client.name, { emitEvent: false });
      })
      .catch(error => console.warn('Error while getting client', error));
  }

  private updateCasesAfterCaseFilterChanges(): void {
    this.caseFilter.valueChanges
      .pipe(takeUntil(this.subKiller), debounceTime(1000))
      .subscribe({
        next: async () => {
          this.pageIndex = 0;
          await this.setCases();
        },
      });
  }

  private updateCasesAfterDebtorFilterChanges(): void {
    this.debtorFilter.valueChanges
      .pipe(takeUntil(this.subKiller), filter((f: string) => f?.length > 2 && !this.loading), debounceTime(1000))
      .subscribe({
        next: async (filter: string) => {
          try {
            this.loading = true;
            const res = await this.debtorsService.searchDebtors({
              name: filter,
              client_id: this.selectedClient?.id,
            });
            this.debtorNames = res.debtors.map(debtor => debtor.name);
          } catch (error) {
            console.error(error);
            this.snackbar.open('Valami hiba történt az adósok betöltésekor!', 'OK', {
              duration: 5000,
            });
          } finally {
            this.loading = false;
          }
        },
      });
  }

  private updateCasesAfterClientFilterChanges(): void {
    this.clientFilter.valueChanges
      .pipe(takeUntil(this.subKiller), filter((f: string) => f?.length > 2 && !this.loading), debounceTime(1000))
      .subscribe({
        next: async (filter: string) => {
          try {
            this.loading = true;
            const res = await this.clientsService.listClients({
              name: filter,
            });
            this.clients = res.clients;
          } catch (error) {
            console.error('Error while loading clients', error);
            this.snackbar.open('Valami hiba történt a hitelezők betöltésekor!', 'OK', {
              duration: 5000,
            });
          } finally {
            this.loading = false;
          }
        },
      });
  }
}
