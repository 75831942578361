import { Injectable } from '@angular/core';
import { Debtor } from 'src/types';
import { environment } from 'src/environments/environment';
import axios from 'axios';

export type GetDebtorsParams = {
  client_id?: string;
  name: string;
};

export type GetDebtorsResponse = {
  debtors: {
    name: string;
  }[];
};

@Injectable({
  providedIn: 'root'
})
export class DebtorsService {
  async searchDebtors(params: GetDebtorsParams): Promise<GetDebtorsResponse> {
    const url = environment.baseUrl + '/api/debtor';
    const result = await axios.get<GetDebtorsResponse>(
      url,
      {
        params,
      },
    );

    return result.data;
  }
}
