import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { Subject } from 'rxjs';
import { WorkflowService } from 'src/app/shared/services/workflow/workflow.service';
import { CasesService, CasesTableData } from 'src/app/services/cases/cases.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-cases-table',
  templateUrl: './cases-table.component.html',
  styleUrls: ['./cases-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CasesTableComponent implements OnChanges, OnDestroy {
  readonly dataSource = new MatTableDataSource<CasesTableData>();
  readonly filterHeader: string[] = [];
  displayedColumns: string[] = [
    'detailsToggle',
    'payeeId',
    'debtors',
    'status',
    'claimsSumAmount',
    'actions',
  ];

  copyTooltipDisabled = true;

  @Input() data: CasesTableData[];
  @Input() length?: number;
  @Input() set isDevModeActive(active: boolean) {
    // const displayedColumns = this.displayedColumns.filter(c => c !== 'debug');
    // if (active) {
    //   displayedColumns.push('debug');
    // }
    // this.displayedColumns = displayedColumns;
  }

  expandedElement: CasesTableData | null = null;
  showAllLogs = false;
  lockExpandedElement = false;

  @Input() pageSize: number;
  @Output() pageSizeChange = new EventEmitter<number>();

  @Input() pageIndex: number;
  @Output() pageIndexChange = new EventEmitter<number>();

  @Output() page = new EventEmitter<PageEvent>();
  @Output() filterCase = new EventEmitter<string>();

  private readonly killSub = new Subject<void>();

  constructor(
    private workflowService: WorkflowService,
    private casesService: CasesService,
  ) { }

  ngOnDestroy(): void {
    this.killSub.next();
    this.killSub.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.dataSource.data = changes.data.currentValue;
    }
  }

  pageChange(event: PageEvent) {
    if (this.pageSize !== event.pageSize) {
      this.pageSize = event.pageSize;
      this.pageSizeChange.emit(event.pageSize);
    }
    if (this.pageIndex !== event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.pageIndexChange.emit(event.pageIndex);
    }

    this.page.emit(event);
  }

  onActionsMenuOpen(element: CasesTableData) {
    this.lockExpandedElement = true;
  }

  onActionsMenuClosed() {
    this.lockExpandedElement = false;
  }

  async runTransitionWithDialog(element: CasesTableData, event: CasesTableData['actions'][number]) {
    const res = await this.workflowService.runTransitionWithDialog({
      transitionId: event.id,
      caseId: element.id,
      dialog: event.dialog,
    });
    if (res) {
      const fresh = await this.casesService.getCases({
        payee_case_id: element.payee_case_reference_id,
        page: 1,
        per_page: 1,
      });
      if (fresh.data.length > 0) {
        Object.assign(element, fresh.data[0]);
      }
    }
  }

  updateExpandedElement(element: CasesTableData) {
    if (this.lockExpandedElement) {
      return;
    }

    if (this.expandedElement === element) {
      this.expandedElement = null;
    } else {
      this.expandedElement = element;
    }
  }

  async copyText(text: string) {
    await navigator.clipboard.writeText(text);
  }

  async openFormOfElement(element: CasesTableData): Promise<void> {
    // TODO
    // const formUrl = element.case.appSiteUrl;
    // const isSzamlazzHu = this.szamlazzhuService.isCaseSzamlazzhu(element.case);
    // if (!isSzamlazzHu) {
    //   window.open(formUrl, '_blank');
    //   return;
    // }
    // const shortToken = await this.szamlazzhuService.createShortTokenForCase(element.client, element.case);
    // const szamlazzhuUrl = `${formUrl}?short_token=${shortToken}`;
    // window.open(szamlazzhuUrl, '_blank');
  }

  filterHistoricCase(id: string): void {
    this.filterCase.emit(id);
  }

  readonly environment = environment;
}
