import {
  Component,
  HostBinding,
  ViewChild
} from '@angular/core';
import { ElementRef } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

import { environment } from '../../../../environments/environment';
import packageJSON from '../../../../../package.json';
import { UsersService } from 'src/app/services/users/users.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  env = environment;
  open = true;
  @ViewChild('appVersion') el: ElementRef;
  @HostBinding('class.close') get close() { return !this.open; }

  get versionNumber() {
    return packageJSON.version;
  }

  get showProductPricePage(): boolean {
    return !!this.usersService.userSubject.value?.can_update_product_price;
  }

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
  ) { }

  toggleOpen() {
    this.open = !this.open;
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }

  readonly environment = environment;
}
