import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClaimForm, PayeeFormGroup } from '../edit-case-dialog.component';
import moment from 'moment';

@Component({
  selector: 'app-add-claim-evidence-dialog',
  templateUrl: './add-claim-evidence-dialog.component.html',
  styleUrls: ['./add-claim-evidence-dialog.component.scss']
})
export class AddClaimEvidenceDialogComponent {
  max = moment();

  constructor(
    public dialogRef: MatDialogRef<AddClaimEvidenceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public fileForm: PayeeFormGroup<ClaimForm['files'][number]>,
  ) {
  }
}
