import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Product, ProductService } from 'src/app/services/product-service/product-service.service';
import { UsersService } from 'src/app/services/users/users.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EditProductDialogComponent } from './edit-product-dialog/edit-product-dialog.component';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-prices',
  templateUrl: './product-prices.component.html',
  styleUrls: ['./product-prices.component.scss']
})
export class ProductPricesComponent implements OnInit, OnDestroy {
  get userName(): string { return this.usersService.userSubject.value?.name ?? ''; }
  readonly dataSource = new MatTableDataSource<Product>();
  readonly displayedColumns = [
    'name',
    'payeeFee',
    'edit',
  ];
  loading = false;

  private readonly destroy = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private productService: ProductService,
    private dialog: MatDialog,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.setDataSource();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  editProduct(product: Product): void {
    const ref = this.dialog.open(EditProductDialogComponent, {
      data: product,
    });
    ref.afterClosed()
      .pipe(first(), takeUntil(this.destroy))
      .subscribe({
        next: async (result?: Product) => {
          if (result) {
            Object.assign(product, result);
          }
        },
      });
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }

  private async setDataSource(): Promise<void> {
    try {
      this.loading = true;
      const result = await this.productService.getProducts();
      this.dataSource.data = result.products;
    } catch (error) {
      console.error('Error while loading products', error);
    } finally {
      this.loading = false;
    }
  }
}
