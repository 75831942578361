<form [formGroup]="formGroup">
  <payee-dropzone
    [multiple]="true"
    height="taller"
    (dropped)="legalBaseEvidencesDropped($event)"
    title="Tölts fel bizonyítékot a jogviszonyhoz"
    description=""
  ></payee-dropzone>

  <mat-error *ngIf="legalRelationshipFiles.hasError('required')">
    Legalább egy fájl feltöltése kötelező
  </mat-error>

  <div
    class="flex vcenter evidence-row"
    *ngFor="let file of legalRelationshipFiles.controls; let i = index"
  >
    <div class="evidence-index">{{ i + 1 }}.</div>
    <span class="grow1">{{ file.value.name || file.value.file_name }}</span>
    <button
      payee-mini-icon-button
      type="button"
      color="white"
      (click)="openLegalBaseEvidence(file.value)"
    >
      <payee-icon [name]="environment.EXTERNAL_LINK_ICON"></payee-icon>
    </button>
    <button
      payee-mini-icon-button
      type="button"
      color="white"
      (click)="removeLegalBaseFile(i)"
    >
      <payee-icon [name]="environment.TRASH_ICON"></payee-icon>
    </button>
  </div>

  <payee-form-field width="wide">
    <span payee-label>Jogviszony típusa</span>
    <payee-autocomplete
      [formControl]="legalRelationshipTypeId"
      [options]="legalRelationshipOptions"
      [displayWith]="legalBaseTypeDisplayWith"
      placeholder="Válassz jogviszonyt!"
    >
    </payee-autocomplete>
  </payee-form-field>

  <payee-form-field width="wide" *ngIf="showLegalBaseSpecification">
    <input payee-input [formControl]="legalRelationshipSpecification" />
    <span payee-label>Jogviszony részletezése</span>
  </payee-form-field>

  <payee-form-field width="wide">
    <input payee-input [formControl]="description" />
    <span payee-label>Szöveges indoklás</span>
  </payee-form-field>
</form>
