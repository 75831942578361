<div class="wrapper flex">
    <div class="sidebar flex column hcenter">
      <div class="intro flex grow1 column hcenter">
        <a href="https://payee.tech"><img class="logo" src="assets/login/payee-logo-white.svg" alt="logo"></a>
        <div class="text">
          <h4>Biztonságos adatkezelés</h4>
          <p>
            Az ügyeid adatait biztonságosan, felhőalapú adatbázisban tároljuk. Nem kell aggódnod miattuk.
        </p>
        </div>
      </div>
    </div>
    <div class="content flex column">
      <div class="form-layout-box flex hcenter vcenter grow1">
        <div class="form-box flex column vcenter">
          <a href="https://payee.tech"><img class="logo" src="assets/login/payee-logo.svg" alt="logo"></a>
          <h3 class="title">Új jelszó sikeresen beállítva!</h3>
          <p class="description">Most már beléphetsz az új jelszavaddal.</p>
          <button mat-flat-button class="login-button" routerLink="login">Belépés</button>
        </div>
      </div>
    </div>
  </div>