import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading/loading.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private loadingService: LoadingService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.setLoading(true, request.url);
    return next.handle(request)
      .pipe(catchError((err) => {
        this.loadingService.setLoading(false, request.url);
        return throwError(err);
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this.loadingService.setLoading(false, request.url);
        }
        return evt;
      }));
  }

  // constructor() {}

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   return from(this.handle(req, next));
  // }

  // async handle(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
  //   console.log('itt');
  //   return next.handle(request).pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       let errorMsg = '';
  //       if (error.error instanceof ErrorEvent) {
  //         console.log('this is client side error');
  //         errorMsg = `Error: ${error.error.message}`;
  //       }
  //       else {
  //         console.log('this is server side error');
  //         errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
  //       }
  //       console.log(errorMsg);
  //       return throwError(errorMsg);
  //     })
  //   ).toPromise();
  // }
}
