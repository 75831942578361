<payee-popup title="Új hitelező">
  <mat-progress-spinner
    *ngIf="initLoading"
    color="accent"
    mode="indeterminate"
    diameter="100"
  ></mat-progress-spinner>
  <form *ngIf="!initLoading" [formGroup]="formGroup">
    <span>Hitelező</span>
    <!-- Client search -->
    <payee-company-search-input-field
      class="client-search"
      [nameFormControl]="name"
      width="400px"
      (companySearchItemSelected)="onCompanySelected($event)"
      (individualSelected)="onIndividualSelected()"
      [canBeInd]="true"
      [canBeHouse]="false"
      [otherSelection]="true"
    >
    </payee-company-search-input-field>
    <!-- Client tax number -->
    <payee-form-field width="wide">
      <span payee-label>Hitelező adószáma</span>
      <input payee-input formControlName="tax_number" />
      <ng-container *ngIf="tax_number.dirty" payee-error>
        <span *ngIf="tax_number.hasError('required')">
          A mező kitöltése kötelező
        </span>
        <span *ngIf="tax_number.hasError('backend')">
          {{ tax_number.getError("backend") }}
        </span>
      </ng-container>
    </payee-form-field>
    <!-- Client address -->
    <form *ngIf="type === 'ind'" [formGroup]="address">
      <h2>{{ name.value || "Hitelező" }} kézbesítési címe</h2>
      <!-- Country -->
      <payee-form-field width="wide">
        <span payee-label>Ország</span>
        <input payee-input formControlName="country" />
      </payee-form-field>
      <div class="flex" style="justify-content: space-between">
        <!-- Postcode -->
        <payee-form-field style="--width: 192px">
          <span payee-label>Irányítószám</span>
          <input payee-input formControlName="postcode" />
          <ng-container *ngIf="postcode.dirty" payee-error>
            <span *ngIf="postcode.hasError('required')">
              A mező kitöltése kötelező
            </span>
          </ng-container>
        </payee-form-field>
        <!-- Settlement -->
        <payee-form-field style="--width: 192px">
          <span payee-label>Település</span>
          <input payee-input formControlName="settlement" />
          <ng-container *ngIf="settlement.dirty" payee-error>
            <span *ngIf="settlement.hasError('required')">
              A mező kitöltése kötelező
            </span>
          </ng-container>
        </payee-form-field>
      </div>
      <!-- Street -->
      <payee-form-field width="wide">
        <span payee-label>Utca és házszám</span>
        <input payee-input formControlName="street" />
        <ng-container *ngIf="street.dirty" payee-error>
          <span *ngIf="street.hasError('required')">
            A mező kitöltése kötelező
          </span>
        </ng-container>
      </payee-form-field>
    </form>
    <!-- Client type -->
    <payee-form-field width="wide">
      <span payee-label>Hitelező típusa</span>
      <payee-autocomplete
        [options]="partyTypeOptions"
        formControlName="party_type_id"
        [displayWith]="partyTypeDisplayWith"
      >
      </payee-autocomplete>
      <ng-container
        payee-error
        *ngIf="party_type_id.dirty && party_type_id.invalid"
      >
        <span *ngIf="party_type_id.hasError('required')">
          A mező kitöltése kötelező
        </span>
        <span *ngIf="party_type_id.hasError('backend')">
          {{ party_type_id.getError("backend") }}
        </span>
      </ng-container>
    </payee-form-field>
    <!-- Default legal relationship -->
    <payee-form-field width="wide">
      <span payee-label
        >Hitelező ügyeihez rendelt alapértelmezett jogviszony</span
      >
      <payee-autocomplete
        [options]="legalRelationshipOptions"
        formControlName="defaultLegalRelationship"
        [displayWith]="legalRelationshipDisplayWith"
      >
      </payee-autocomplete>
      <ng-container
        payee-error
        *ngIf="
          defaultLegalRelationship.dirty && defaultLegalRelationship.invalid
        "
      >
        <span *ngIf="defaultLegalRelationship.hasError('required')">
          A mező kitöltése kötelező
        </span>
        <span *ngIf="defaultLegalRelationship.hasError('backend')">
          {{ defaultLegalRelationship.getError("backend") }}
        </span>
      </ng-container>
    </payee-form-field>
    <!-- Default claim type -->
    <payee-form-field width="wide">
      <span payee-label
        >Hitelező ügyeihez rendelt alapértelmezett követeléstípus</span
      >
      <payee-autocomplete
        [options]="claimTypeOptions"
        formControlName="defaultClaimType"
        [displayWith]="claimTypeDisplayWith"
      >
      </payee-autocomplete>
      <ng-container
        payee-error
        *ngIf="defaultClaimType.dirty && defaultClaimType.invalid"
      >
        <span *ngIf="defaultClaimType.hasError('required')">
          A mező kitöltése kötelező
        </span>
        <span *ngIf="defaultClaimType.hasError('backend')">
          {{ defaultClaimType.getError("backend") }}
        </span>
      </ng-container>
    </payee-form-field>
    <!-- Sync PwC invoices from -->
    <payee-form-field width="wide">
      <div payee-label class="flex vcenter">
        <span>Számlák szinkronizálása ettől a dátumtól kezdve</span>
        <span
          class="Appkit4-icon icon-circle-warning-outline"
          color="alert-red"
          ap-tooltip
          direction="top"
          tooltipContent="A szinkronizálás sok időbe telhet!"
        ></span>
      </div>
      <payee-datepicker
        formControlName="pwc_sync_from"
        [max]="pwcSyncMax"
        [min]="pwcSyncMin"
      >
      </payee-datepicker>
    </payee-form-field>
    <!-- Errors that didn't match any fields in the form -->
    <div
      color="alert-red"
      payee-error
      *ngIf="formGroup.dirty && formGroup.invalid"
    >
      <span *ngIf="formGroup.hasError('backend')">
        {{ formGroup.getError("backend") }}
      </span>
    </div>
  </form>
  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      width="wide"
      [disabled]="formGroup?.invalid"
      (click)="submit()"
    >
      Mentés
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button payee-button color="alert-red" width="wide" mat-dialog-close="">
      Mégse
    </button>
  </ng-template>
</payee-popup>
