import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TitleService } from 'src/app/services/title/title.service';

@Component({
  selector: 'app-forgot-password-new',
  templateUrl: './forgot-password-new.component.html',
  styleUrls: ['./forgot-password-new.component.scss']
})
export class ForgotPasswordNewComponent {
  passwordChanged: boolean;
  passwordForm: FormControl;

  actionCode: string;
  apiKey: string;
  continueUrl: string;
  email: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: TitleService,
  ) {
    this.passwordForm = this.fb.control('', [Validators.required, Validators.minLength(6)]);
    this.titleService.setTitle('Új jelszó beállítása');
    this.route.queryParams.subscribe(param => {
      this.continueUrl = param.continueUrl;
      const url = new URL(this.continueUrl);
      this.email = url.searchParams.get('email') as string;
    });
  }

  async setNewPassword(): Promise<void> {
    throw new Error('Unimplemented');
  }

}
