<div class="content flex column">
  <div class="toolbar flex">
    <div class="schema-selector-box flex column">
      <button
        class="menu-button"
        mat-button
        [matMenuTriggerFor]="enumListMenu"
        *ngIf="enumList?.length > 0"
        [disabled]="enumList.length <= 1 || hasChanged"
      >
        <ng-container *ngIf="selectedEnum">{{
          selectedEnum.label
        }}</ng-container>
        <ng-container *ngIf="!selectedEnum">Válassz sémát!</ng-container>
      </button>
      <button class="menu-button" mat-button *ngIf="enumList?.length == 0">
        Nincsenek sémák!
      </button>
      <mat-menu #enumListMenu xPosition="after" class="no-padding">
        <button
          mat-menu-item
          class="schema-menu-item"
          *ngFor="let enumItem of enumList"
          (click)="selectEnum(enumItem)"
        >
          <div class="flex vcenter">
            {{ enumItem.label }}
          </div>
        </button>
      </mat-menu>
    </div>

    <div class="save-buttons-box flex column">
      <button
        mat-button
        class="save-button"
        [disabled]="!hasChanged"
        (click)="saveSchema()"
      >
        Mentés
      </button>
      <button
        mat-button
        class="save-button"
        [disabled]="!hasChanged"
        (click)="discardChanges()"
      >
        Elvetés
      </button>
    </div>

    <div class="save-buttons-box flex column">
      <button mat-button class="save-button" (click)="downloadselectedEnum()">
        Exportálás
      </button>
      <input
        type="file"
        accept="application/json"
        style="display: none"
        #importInput
        (change)="importSchema(importInput.files); importInput.value = ''"
      />
      <button mat-button class="save-button" (click)="importInput.click()">
        Importálás
      </button>
    </div>

    <div class="adjust-buttons-box flex column hcenter">
      <div class="input-slider-box flex vcenter">
        <label class="input-slider-label-title">Form-ok széllesége</label>
        <label class="input-slider-label-value">{{ inputWidth }}px</label>
      </div>
      <mat-slider
        max="250"
        min="50"
        step="5"
        color="primary"
        [(ngModel)]="inputWidth"
      >
      </mat-slider>
    </div>
  </div>

  <div *ngIf="selectedEnum" class="schema-grid flex column">
    <div
      *ngFor="let schemaRow of getPaginatedSchema(); let i = index"
      class="data-row flex vcenter"
    >
      <ng-container *ngFor="let column of selectedEnum.descriptor.columns">
        <!--INT-->
        <ng-container *ngIf="column.type == 'int'">
          <app-custom-input-field>
            <input
              custom-input
              #input
              pattern="\d+"
              [value]="schemaRow[column.name]"
              [readOnly]="column.readonly"
              (input)="
                updateSchemaElement(
                  input.value,
                  i + page * pageSize,
                  column.name
                )
              "
            />
            <span custom-label [class.custom-label-top]="!!input.value">{{
              column.name
            }}</span>
          </app-custom-input-field>
        </ng-container>
        <!--STRING-->
        <ng-container *ngIf="column.type == 'string'">
          <app-custom-input-field>
            <input
              custom-input
              #input
              [value]="schemaRow[column.name]"
              [readOnly]="column.readonly"
              (input)="
                updateSchemaElement(
                  input.value,
                  i + page * pageSize,
                  column.name
                )
              "
            />
            <span custom-label [class.custom-label-top]="!!input.value">{{
              column.name
            }}</span>
          </app-custom-input-field>
        </ng-container>
        <!--BOOLEAN-->
        <ng-container *ngIf="column.type == 'boolean'">
          <app-custom-checkbox
            [checked]="schemaRow[column.name]"
            [disabled]="column.readonly"
            (changed)="
              updateSchemaElement($event, i + page * pageSize, column.name)
            "
          >
            <span>{{ column.name }}</span>
          </app-custom-checkbox>
        </ng-container>
        <!--ENUM-->
        <ng-container *ngIf="column.type == 'enum'">
          <app-custom-input-field>
            <button
              custom-input
              mat-button
              class="field-menu-button"
              [matMenuTriggerFor]="enumMenu"
              [disabled]="column.disabled"
            >
              {{ schemaRow[column.name] }}
            </button>
            <mat-menu #enumMenu xPosition="after" class="no-padding">
              <button
                mat-menu-item
                class="field-menu-item"
                *ngFor="let enumValue of column.values"
                (click)="
                  updateSchemaElement(
                    enumValue,
                    i + page * pageSize,
                    column.name
                  )
                "
              >
                {{ enumValue }}
              </button>
            </mat-menu>
            <span custom-label class="custom-label-top">{{ column.name }}</span>
          </app-custom-input-field>
        </ng-container>
        <!--EXTERNAL-->
        <ng-container *ngIf="column.type == 'external'">
          <app-custom-input-field>
            <button
              custom-input
              mat-button
              class="field-menu-button"
              [matMenuTriggerFor]="schemaMenu"
              [disabled]="column.readonly"
            >
              {{ getExternalLabel(schemaRow, column) }}
            </button>
            <mat-menu #schemaMenu xPosition="after" class="no-padding">
              <button
                mat-menu-item
                class="field-menu-item"
                *ngFor="let externalItem of enums[column.externalModel]"
                (click)="
                  updateSchemaElement(
                    externalItem.id,
                    i + page * pageSize,
                    column.name
                  )
                "
              >
                {{ getLabel(externalItem) }}
              </button>
            </mat-menu>
            <span custom-label class="custom-label-top">{{ column.name }}</span>
          </app-custom-input-field>
        </ng-container>
        <!--ARRAY-->
        <ng-container *ngIf="column.type == 'array'">
          <app-custom-input-field>
            <button
              custom-input
              mat-button
              class="field-menu-button"
              (click)="editArray(schemaRow, column.name)"
              [disabled]="column.readonly"
            >
              {{ getArraySummary(schemaRow, column.name) }}
            </button>
            <span custom-label class="custom-label-top">{{ column.name }}</span>
          </app-custom-input-field>
        </ng-container>
      </ng-container>

      <button
        mat-icon-button
        (click)="deleteSchemaElement(i + page * pageSize)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <div class="paginator flex vcenter" *ngIf="selectedEnum">
    <button
      mat-button
      class="new-element-button"
      (click)="addEnumElement()"
      *ngIf="selectedEnum"
    >
      Új elem felvétele
    </button>
    <button mat-icon-button (click)="prevPage()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-icon-button (click)="nextPage()">
      <mat-icon>chevron_right</mat-icon>
    </button>
    <div>
      {{ page * pageSize + 1 }} -
      {{ min((page + 1) * pageSize, selectedEnum.items.length) }}
    </div>
    &nbsp;
    <div>Összesen: {{ selectedEnum.items.length }}</div>
    &nbsp;
    <button
      class="paginator-menu-button"
      mat-button
      [matMenuTriggerFor]="pageSizeMenu"
    >
      {{ pageSize }}
    </button>
    <mat-menu #pageSizeMenu xPosition="after" class="no-padding">
      <button
        mat-menu-item
        class="paginator-menu-item"
        *ngFor="let option of pageSizeOptions"
        (click)="setPageSize(option)"
      >
        <div class="flex vcenter">
          {{ option }}
        </div>
      </button>
    </mat-menu>
  </div>
</div>
