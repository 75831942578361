import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  async getAllowedClaimTypes(legalRelationshipId: number): Promise<number[]> {
    const url = environment.baseUrl + `/api/allowed-claim-types/${legalRelationshipId}`;
    const result = await axios.get<number[]>(url);

    return result.data;
  }
}
