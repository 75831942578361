import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type GetRolesResponse = {
  roles: {
    name: string;
  }[];
};

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  async getRoles(): Promise<GetRolesResponse> {
    const url = environment.baseUrl + `/api/role`;
    const response = await axios.get(url);
    return response.data;
  }

  async updateRoles(user_uuid: string, roles: Record<string, boolean>): Promise<void> {
    const url = environment.baseUrl + `/api/role/${user_uuid}`;
    const response = await axios.patch(url, {
      roles,
    });
    return response.data;
  }
}
