import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type ListEnumsResponse = {
  enums: {
    model: string;
    label: string;
  }[];
};

export type UpdateEnumParams = {
  model: string;
  items: unknown[];
};

@Injectable({
  providedIn: 'root'
})
export class EnumsService {
  async listEnums(): Promise<ListEnumsResponse> {
    const res = await axios.get<ListEnumsResponse>(
      environment.baseUrl + '/api/enum'
    );

    return res.data;
  }

  async getEnum<T = unknown>(model: string): Promise<T[]> {
    const res = await axios.get<T[]>(
      environment.baseUrl + '/api/enum/' + encodeURIComponent(model),
    );

    return res.data;
  }

  async updateSchema(params: UpdateEnumParams): Promise<void> {
    await axios.put(
      environment.baseUrl + '/api/enum/' + encodeURIComponent(params.model),
      params,
    );
  }
}
