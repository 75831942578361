import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(
    private title: Title,
  ) { }

  setTitle(title: string): void {
    const suffix = environment.titleSuffix;

    if (suffix) {
      this.title.setTitle(`Real-time Debt Management Tool Admin ${suffix}`);
    } else {
      this.title.setTitle('Real-time Debt Management Tool Admin');
    }
    // if (suffix) {
    //   this.title.setTitle(`${title} | Payee Admin ${suffix}`);
    // } else {
    //   this.title.setTitle(title);
    // }
  }
}
