import { ForgotPasswordSuccessComponent } from './components/login/forgot-password-success/forgot-password-success.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { ForgotPasswordNewComponent } from './components/login/forgot-password-new/forgot-password-new.component';
import { LoginComponent } from './components/login/login/login.component';
import { EnumsComponent } from './components/dashboard/enums/enums.component';
import { CasesListComponent } from './components/dashboard/cases-list/cases-list.component';
import { ClientsListComponent } from './components/dashboard/clients-list/clients-list.component';
import { RolesComponent } from './components/dashboard/roles/roles.component';
import {
  OauthRedirectComponent as PwcOauthRedirectComponent,
} from 'src/app/shared/pwc/oauth-redirect/oauth-redirect.component';
import { UserNotFoundComponent } from './shared/user-not-found/user-not-found.component';
import { ProductPricesComponent } from './components/dashboard/product-prices/product-prices.component';
import { AirtableEmailTestComponent } from './components/dashboard/airtable-email-test/airtable-email-test.component';
import { WorkflowEmailTestComponent } from './components/dashboard/workflow-email-test/workflow-email-test.component';

const routes: Routes = [
  {
    path: 'admin',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'airtable-email-test', component: AirtableEmailTestComponent },
      { path: 'workflow-email-test', component: WorkflowEmailTestComponent },
      // {
      //   path: 'users-list',
      //   component: UsersListComponent,
      // },
      {
        path: 'clients-list',
        component: ClientsListComponent,
      },
      {
        path: 'cases-list',
        component: CasesListComponent,
      },
      {
        path: 'enums',
        component: EnumsComponent,
      },
      {
        path: 'roles',
        component: RolesComponent,
      },
      {
        path: 'product-prices',
        component: ProductPricesComponent,
      },
      { path: '**', redirectTo: 'cases-list' },
    ],
  },
  {
    path: 'oauth',
    children: [
      { path: 'pwc', component: PwcOauthRedirectComponent },
    ],
  },
  { path: 'login', component: LoginComponent },
  { path: 'user-not-found', component: UserNotFoundComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password-new', component: ForgotPasswordNewComponent },
  { path: 'forgot-password-success', component: ForgotPasswordSuccessComponent },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
