import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-forgot-password-success',
  templateUrl: './forgot-password-success.component.html',
  styleUrls: ['./forgot-password-success.component.scss']
})
export class ForgotPasswordSuccessComponent {

  constructor(
    private title: Title
  ) {
    this.title.setTitle('Sikeres jelszó változtatás | Payee Admin (Testing)')
  }
}
