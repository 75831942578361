import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CasesService } from 'src/app/services/cases/cases.service';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { EnumsService } from 'src/app/services/enums/enums.service';
import { claimType, legalRelationship, partyType } from 'src/app/components/dashboard/enums/enum-descriptors';
import { ClaimType, LegalRelationship, PartyType } from 'src/types';
import { CompanySearchAutocompleteItem } from 'src/app/shared/company-search-service/company-search.service';
import { AxiosError } from 'axios';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-create-client-dialog',
  templateUrl: './create-client-dialog.component.html',
  styleUrls: ['./create-client-dialog.component.scss']
})
export class CreateClientDialogComponent implements OnInit, OnDestroy {
  readonly formGroup: FormGroup;

  submitLoading = false;
  initLoading = false;

  private partyTypes: PartyType[] = [];
  private legalRelationships: LegalRelationship[] = [];
  private claimTypes: ClaimType[] = [];
  partyTypeOptions: number[] = [];
  legalRelationshipOptions: number[] = [];
  claimTypeOptions: number[] = [];

  readonly pwcSyncMin: Moment;
  readonly pwcSyncMax: Moment;

  private readonly destroy = new Subject<void>();

  type?: 'ind' | 'org';
  get address(): FormGroup { return this.formGroup.get('address') as FormGroup; }
  get country(): FormControl { return this.address.get('country') as FormControl; }
  get settlement(): FormControl { return this.address.get('settlement') as FormControl; }
  get postcode(): FormControl { return this.address.get('postcode') as FormControl; }
  get street(): FormControl { return this.address.get('street') as FormControl; }
  get tax_number(): FormControl { return this.formGroup.get('tax_number') as FormControl; }
  get name(): FormControl { return this.formGroup.get('name') as FormControl; }
  get party_type_id(): FormControl { return this.formGroup.get('party_type_id') as FormControl; }
  get defaultLegalRelationship(): FormControl { return this.formGroup.get('defaultLegalRelationship') as FormControl; }
  get defaultClaimType(): FormControl { return this.formGroup.get('defaultClaimType') as FormControl; }
  get pwc_sync_from(): FormControl { return this.formGroup.get('pwc_sync_from') as FormControl; }

  constructor(
    public dialogRef: MatDialogRef<CreateClientDialogComponent>,
    private fb: FormBuilder,
    private casesService: CasesService,
    private claimsService: ClaimsService,
    private snackbar: MatSnackBar,
    private clientsService: ClientsService,
    private enumsService: EnumsService,
    private helpersService: HelpersService,
  ) {
    this.pwcSyncMin = moment().subtract(5, 'years').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    this.pwcSyncMax = moment().subtract(1, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    this.formGroup = this.fb.group({
      tax_number: ['', Validators.required],
      name: ['', Validators.required],
      party_type_id: [null, Validators.required],
      defaultLegalRelationship: [null, Validators.required],
      defaultClaimType: [null, Validators.required],
      pwc_sync_from: [null, [
        Validators.required,
        this.helpersService.afterOrEqual(this.pwcSyncMin),
        this.helpersService.beforeOrEqual(this.pwcSyncMax),
      ]],
      address: this.fb.group({
        country: [{ value: 'Magyarország', disabled: true }],
        settlement: [null, Validators.required],
        postcode: [null, Validators.required],
        street: [null, Validators.required],
      }),
    });
  }

  async ngOnInit(): Promise<void> {
    this.updateAllowedClaimTypesAfterLegalRelationshipChanges();
    this.updateAllowedLegalRelationshipsAfterClaimTypeChanges();

    try {
      this.initLoading = true;

      const [
        partyTypes,
        claimTypes,
        legalRelationships,
      ] = await Promise.all([
        this.enumsService.getEnum<PartyType>(partyType.model),
        this.enumsService.getEnum<ClaimType>(claimType.model),
        this.enumsService.getEnum<LegalRelationship>(legalRelationship.model),
      ]);

      this.partyTypes = partyTypes;
      this.partyTypeOptions = partyTypes.map(pt => pt.id);
      this.claimTypes = claimTypes;
      this.claimTypeOptions = claimTypes.map(c => c.id);
      this.legalRelationships = legalRelationships;
      this.legalRelationshipOptions = legalRelationships.map(lr => lr.id);
    } catch (error) {
      console.error(error);
      this.snackbar.open('Valami hiba történt a dialog megnyitásakor', 'OK', {
        duration: 5000,
      });
      this.dialogRef.close();
    } finally {
      this.initLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  onCompanySelected(item: CompanySearchAutocompleteItem): void {
    this.type = 'org';
    this.tax_number.patchValue(item.tax_number);
    this.tax_number.disable();
    this.party_type_id.patchValue(+item.party_type_id);
    this.address.patchValue({
      country: item.country,
      settlement: item.street,
      postcode: item.settlement,
      street: item.postcode,
    });
    this.address.disable();

    this.partyTypeOptions = this.partyTypes.filter(pt => pt.type === 'org').map(pt => pt.id);
  }

  onIndividualSelected(): void {
    this.type = 'ind';
    this.tax_number.patchValue(null);
    this.tax_number.enable();
    const indPartyType = this.partyTypes.find(pt => pt.internal_id === '2-a');
    this.party_type_id.patchValue(indPartyType?.id);
    this.address.patchValue({
      settlement: null,
      postcode: null,
      street: null,
    });
    this.address.enable();

    this.partyTypeOptions = this.partyTypes.filter(pt => pt.type === 'ind').map(pt => pt.id);
  }

  async submit() {
    if (this.formGroup.invalid || this.submitLoading || !this.type) {
      console.warn('Submit not available', {
        isFormValid: this.formGroup.valid,
        isSubmitLoading: this.submitLoading,
        isTypeSet: !!this.type,
      });
      return;
    }

    try {
      this.submitLoading = true;

      if (this.type === 'ind') {
        this.clientsService.createClient({
          type: 'ind',
          address: {
            country: 'HU',
            postcode: this.postcode.value,
            settlement: this.settlement.value,
            street: this.street.value,
          },
          name: this.name.value,
          tax_number: this.tax_number.value,
          party_type_id: this.party_type_id.value,
          default_legal_relationship_id: this.defaultLegalRelationship.value,
          default_claim_type_id: this.defaultClaimType.value,
          pwc_sync_from: this.pwc_sync_from.value.format('YYYY-MM-DD'),
        });
      } else {
        await this.clientsService.createClient({
          type: 'org',
          tax_number: this.tax_number.value,
          party_type_id: this.party_type_id.value,
          default_legal_relationship_id: this.defaultLegalRelationship.value,
          default_claim_type_id: this.defaultClaimType.value,
          pwc_sync_from: this.pwc_sync_from.value.format('YYYY-MM-DD'),
        });
      }

      this.dialogRef.close(this.name.value);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 422) {
          this.helpersService.markAllChildrenAsDirty(this.formGroup);
          const errors: Record<string, string[]> = error.response.data.errors;
          Object.entries(errors).forEach(([key, values]) => {
            const control = this.formGroup.get(key);
            if (control) {
              control.setErrors({
                backend: values[0],
              });
            } else {
              this.formGroup.setErrors({
                backend: values[0],
              });
            }
          });
          return;
        }
      }
      console.error('Error while creating client', error);
      this.snackbar.open('Valami hiba történt mentéskor!', 'OK', {
        duration: 5000,
      });
      this.dialogRef.close();
    } finally {
      this.submitLoading = false;
    }
  }

  readonly partyTypeDisplayWith = (id: number): string => {
    return this.partyTypes.find(t => t.id === id)?.label || '';
  };

  readonly legalRelationshipDisplayWith = (id: number): string => {
    return this.legalRelationships.find(t => t.id === id)?.label || '';
  };

  readonly claimTypeDisplayWith = (id: number): string => {
    return this.claimTypes.find(t => t.id === id)?.label || '';
  };

  private updateAllowedClaimTypesAfterLegalRelationshipChanges(): void {
    this.defaultLegalRelationship.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: async (id?: number) => {
          if (id) {
            this.claimTypeOptions = await this.claimsService.getAllowedClaimTypes(id);
          } else {
            this.claimTypeOptions = this.claimTypes.map(t => t.id);
          }

          const defaultClaimType = this.defaultClaimType.value as number;
          if (!this.claimTypeOptions.includes(defaultClaimType)) {
            this.defaultClaimType.patchValue(null);
          }
        },
      });
  }

  private updateAllowedLegalRelationshipsAfterClaimTypeChanges(): void {
    this.defaultClaimType.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: async (id?: number) => {
          if (id) {
            this.legalRelationshipOptions = await this.casesService.getAllowedLegalRelationships([id]);
          } else {
            this.legalRelationshipOptions = this.legalRelationships.map(t => t.id);
          }

          const defaultLegalRelationship = this.defaultLegalRelationship.value as number;
          if (!this.legalRelationshipOptions.includes(defaultLegalRelationship)) {
            this.defaultLegalRelationship.patchValue(null);
          }
        },
      });
  }
}
