import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PartyType, RepresentativeType } from 'src/types';
import { ClientFormGroup, RepresentativeFormGroup } from '../edit-case-dialog.component';
import { GetEditCaseResponse } from 'src/app/services/cases/cases.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})
export class ClientFormComponent implements OnChanges {
  @Input() formGroup: ClientFormGroup;
  @Input() client: GetEditCaseResponse["client"];
  @Input() partyTypes: PartyType[] = [];
  @Input() representativeTypes: RepresentativeType[] = [];

  partyTypeOptions: number[] = [];
  representativeTypeOptions: number[] = [];

  get partyTypeId(): FormControl { return this.formGroup.get('party_type_id') as FormControl; }
  get email(): FormControl { return this.formGroup.get('email') as FormControl; }
  get phone(): FormControl { return this.formGroup.get('phone') as FormControl; }
  get representative(): RepresentativeFormGroup { return this.formGroup.get('representative') as RepresentativeFormGroup; }
  get representativeEmail(): FormControl { return this.representative.get('email') as FormControl; }
  get representativePhone(): FormControl { return this.representative.get('phone') as FormControl; }
  get representativeType(): FormControl { return this.representative.get('representative_type_id') as FormControl; }

  get clientHasRepresentative(): boolean {
    return this.client.representative.name !== null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.representativeTypes) {
      this.representativeTypeOptions = changes.representativeTypes.currentValue.map((rt: RepresentativeType) => rt.id);
    }
    if (changes.partyTypes) {
      this.partyTypeOptions = changes.partyTypes.currentValue.map((pt: PartyType) => pt.id);
    }
  }

  readonly repTypeDisplayWith = (id: number): string => {
    return this.representativeTypes.find(rt => rt.id === id)?.label || '';
  };

  readonly partyTypeDisplayWith = (id: number): string => {
    return this.partyTypes.find(pt => pt.id == id)?.label || '';
  };

  readonly environment = environment;
}
