import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolesComponent } from './roles.component';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreateUserDialogModule } from './create-user-dialog/create-user-dialog.module';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { EditUserDialogModule } from './edit-user-dialog/edit-user-dialog.module';



@NgModule({
  declarations: [
    RolesComponent,
  ],
  imports: [
    CommonModule,
    CreateUserDialogModule,
    EditUserDialogModule,
    PayeeFormFieldsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    PayeeButtonsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ]
})
export class RolesModule { }
