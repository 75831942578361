import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iban'
})
export class IbanPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const result: string[] = [];
    value = value.replace(/[^\d]/g, '');
    while (value.length >= 8) {
        result.push(value.substring(0, 8));
        value = value.substring(8);
    }
    if (value.length > 0) {  result.push(value);  }

    return result.join('-');
  }

}
