<payee-popup title="Bizonyíték feltöltése">
  <div
    class="flex vcenter file-row"
  >
    <span class="file-index">{{1.}}.</span>
    <span class="file-name">{{fileForm.value.file_name}}</span>
  </div>

  <form [formGroup]="fileForm">
    <payee-form-field width="wide">
      <span payee-label>Számla kelte</span>
      <payee-datepicker
        formControlName="invoice_date"
        [max]="max"
      >
      </payee-datepicker>
    </payee-form-field>
    <payee-form-field width="wide">
      <input
        payee-input
        formControlName="invoice_number"
      >
      <span payee-label>Számla sorszáma</span>
    </payee-form-field>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      width="wide"
      mat-dialog-close
    >
      Mentés
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate></ng-template>
</payee-popup>