import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import axios from 'axios';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users/users.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-airtable-email-test',
  templateUrl: './airtable-email-test.component.html',
  styleUrls: ['./airtable-email-test.component.scss']
})
export class AirtableEmailTestComponent implements OnInit, OnDestroy {
  readonly formGroup: FormGroup;
  userName?: string;

  private readonly destroy = new Subject<void>();

  get template(): FormControl { return this.formGroup.get('template') as FormControl; }
  get payee_case_id(): FormControl { return this.formGroup.get('payee_case_id') as FormControl; }

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private fb: FormBuilder,
  ) {
    this.formGroup = this.fb.group({
      template: ['', Validators.required],
      payee_case_id: ['P1001', Validators.required],
    });
  }

  ngOnInit(): void {
    this.usersService.userSubject
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: user => {
          const parts = user?.name?.split(' ', 2);
          this.userName = parts?.map(p => p[0])?.join('');
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async submit(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }

    await axios.post(environment.baseUrl + '/api/test-email/template', {
      payee_case_reference_id: this.payee_case_id.value,
      template: this.template.value,
    });
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }
}
