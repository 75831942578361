import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, takeUntil } from 'rxjs/operators';
import { RolesService } from 'src/app/services/roles/roles.service';
import { UserDataTableItem, UsersService } from 'src/app/services/users/users.service';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { CreateUserDialogComponent } from 'src/app/components/dashboard/roles/create-user-dialog/create-user-dialog.component';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit, OnDestroy {
  readonly userFormControl: FormControl;
  readonly dataSource = new MatTableDataSource<UserDataTableItem>();
  roles: string[] = [];
  tableLoading = false;
  readonly displayedColumns = [
    'email',
    'user_name',
    'role_name',
    'clients',
    'edit_roles',
  ] as const;

  pageSize = 10;
  pageIndex = 0;
  length = 0;

  sort_by?: keyof UserDataTableItem;
  sort_direction: 'asc' | 'desc' = 'asc';

  private readonly destroy = new Subject<void>();

  constructor(
    private rolesService: RolesService,
    private usersService: UsersService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {
    this.userFormControl = this.fb.control('');
  }

  async ngOnInit(): Promise<void> {
    const [{ roles }] = await Promise.all([
      this.rolesService.getRoles(),
      this.setUsers(),
    ]);
    this.roles = roles.map(r => r.name);

    this.userFormControl.valueChanges
      .pipe(takeUntil(this.destroy), debounceTime(1000), distinctUntilChanged())
      .subscribe({
        next: async () => {
          this.pageIndex = 0;
          await this.setUsers();
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async pageChange(event: PageEvent) {
    if (event.pageSize !== this.pageSize) {
      this.pageSize = event.pageSize;
    }
    if (event.pageIndex !== this.pageIndex) {
      this.pageIndex = event.pageIndex;
    }
    await this.setUsers();
  }

  async sortData(event: Sort): Promise<void> {
    this.sort_by = event.active as keyof UserDataTableItem;
    this.sort_direction = event.direction || 'asc';

    await this.setUsers();
  }

  editUser(user: UserDataTableItem): void {
    const ref = this.dialog.open(EditUserDialogComponent, {
      data: user,
    });
    ref.afterClosed().pipe(first())
      .subscribe({
        next: async (updated: boolean) => {
          if (updated) {
            await this.setUsers();
          }
        },
      });
  }

  createUser(): void {
    const ref = this.dialog.open(CreateUserDialogComponent);
    ref.afterClosed().pipe(first())
      .subscribe({
        next: async (email?: string) => {
          if (email) {
            this.userFormControl.patchValue(email);
          }
        },
      });
  }

  private async setUsers(filter: string = this.userFormControl.value) {
    try {
      this.tableLoading = true;
      const res = await this.usersService.getUsersDataTable({
        user_email: filter || undefined,
        page: this.pageIndex + 1,
        per_page: this.pageSize,
        sort_by: this.sort_by,
        sort_direction: this.sort_direction,
      });

      this.length = res.pagination.total;
      this.dataSource.data = res.data;
    } catch (error) {
      console.error('Error while loading users', error);
    } finally {
      this.tableLoading = false;
    }
  }
}
