<payee-popup
  title="Hitelező törlése"
  description="Biztos kitörli? A műveletet nem lehet visszafordítani!"
>
  <div class="flex vcenter">
    <span>Hitelező neve: {{ client.name }}</span>
  </div>
  <div class="flex vcenter">
    <span>Hitelező adószáma: {{ client.tax_number }}</span>
  </div>
  <ng-template #payeePopupSubmitTemplate>
    <button
      [disabled]="submitLoading"
      payee-button
      width="wide"
      (click)="submit()"
    >
      Törlés
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button payee-button color="alert-red" width="wide" mat-dialog-close>
      Mégse
    </button>
  </ng-template>
</payee-popup>
