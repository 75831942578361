<div class="wrapper">
  <div class="title">Tömb szerkesztése</div>

  <div class="elements-box flex column vcenter">
    <div *ngFor="let control of array.controls, let i=index;" class="element-box flex vcenter">
      <app-custom-input-field>
        <input custom-input [formControl]="control">
      </app-custom-input-field>
      <button type="button" mat-icon-button (click)="removeElement(i)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <button type="button" mat-button class="basic-button" (click)="addElement()">Elem hozzáadása</button>

  <div class="close-buttons-box flex column">
    <button mat-button class="basic-button save-button" (click)="submit()">Tömb felülírása</button>
    <button mat-button class="basic-button close-button" mat-dialog-close>Mégse</button>
  </div>
</div>