<header class="flex vcenter">
  <ap-button [icon]="'logout-fill'" (onClick)="logout()"></ap-button>
  <button payee-button color="grey-light" class="name-button">
    {{ userName }}
  </button>
</header>

<main class="flex column">
  <h1>Termékek</h1>
  <table
    *ngIf="!loading"
    mat-table
    [dataSource]="dataSource"
    class="top-margin-20 mat-elevation-z1"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Termék neve</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="payeeFee">
      <th mat-header-cell *matHeaderCellDef>Ügyindítási díj</th>
      <td mat-cell *matCellDef="let element">
        <div class="price-box flex vcenter">
          <span class="price-amount">
            {{ element.payee_fee | number : "1.0" : "hu" }}
            {{ element.currency }} + Áfa
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <ap-button
          icon="edit-outline"
          btnType="tertiary"
          [compact]="true"
          (click)="editProduct(element)"
        ></ap-button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
      class="main-header"
    ></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
  </table>
</main>

<mat-progress-spinner
  *ngIf="loading"
  class="loading-spinner"
  color="accent"
  diameter="100"
  mode="indeterminate"
></mat-progress-spinner>
