import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Schema } from 'src/types';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { EnumsService, ListEnumsResponse } from 'src/app/services/enums/enums.service';
import { SchemaArrayFieldEditorDialogComponent } from 'src/app/helper/schema-array-field-editor-dialog/schema-array-field-editor-dialog.component';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TitleService } from 'src/app/services/title/title.service';
import { EnumDescriptor, EnumDescriptorColumn, enumDescriptors } from './enum-descriptors';
import { z } from 'zod';

@Component({
  selector: 'app-enums',
  templateUrl: './enums.component.html',
  styleUrls: ['./enums.component.scss']
})
export class EnumsComponent {
  enumList: ListEnumsResponse['enums'] = [];
  enums: Record<string, unknown[] | null> = {};
  selectedEnum: {
    label: string;
    model: string;
    items: any[];
    descriptor: EnumDescriptor;
  } | null = null;

  country: string | null = null;
  hasChanged = false;
  pageSize = 10;
  readonly pageSizeOptions = [5, 10, 25, 100];
  page = 0;

  private _inputWidth: number = 100;
  get inputWidth() { return this._inputWidth; }
  set inputWidth(width: number) {
    const root = document.documentElement;
    this._inputWidth = width;
    root.style.setProperty('--input-width', `${width}px`);
    localStorage.setItem('schemas/inputWidth', width.toString());
  }

  constructor(
    private helpersService: HelpersService,
    private dialog: MatDialog,
    private enumsService: EnumsService,
    private snackbar: MatSnackBar,
    private titleService: TitleService,
  ) {
    this.titleService.setTitle('Enumok beállítása');
  }

  async ngOnInit() {
    const enumList = await this.enumsService.listEnums();
    this.enumList = enumList.enums;
    this.selectEnum(this.enumList[0]);
    this.setDefaultInputWidth();
  }

  setDefaultInputWidth() {
    const stored = localStorage.getItem('schemas/inputWidth');
    this.inputWidth = parseInt(stored || '100') || 100;
  }

  async selectEnum(enumItem: typeof this.enumList[number]) {
    const res = await this.enumsService.getEnum(enumItem.model);
    this.enums[enumItem.model] = res;

    const descriptor = enumDescriptors.find(d => d.model === enumItem.model);

    if (!descriptor) {
      this.snackbar.open('Hiányzik az enum leírója!', 'OK', {
        duration: 5000,
      });
      return;
    }

    await Promise.all(descriptor.columns.map(async column => {
      if (column.type !== 'external') {
        return;
      }

      if (!column.externalModel) {
        this.snackbar.open(`Hiányzik az enum külső hivatkozása ${column.name}`, 'OK', {
          duration: 5000,
        });
        return;
      }

      if (column.externalModel in this.enums) {
        return;
      }

      this.enums[column.externalModel] = null;
      const external = await this.enumsService.getEnum(column.externalModel);
      this.enums[column.externalModel] = external;
    }));

    this.selectedEnum = {
      items: res,
      label: enumItem.label,
      model: enumItem.model,
      descriptor,
    };

    this.fixPage();
  }

  addEnumElement() {
    const emptyElement: object = {};

    // Object.keys(this.selectedEnum).forEach(key => {
    //   if (key.type === 'enum') {
    //     emptyElement[key.name] = key.values[0];
    //   } else if (key.type === 'array') {
    //     emptyElement[key.name] = [];
    //   } else {
    //     emptyElement[key.name] = null;
    //   }
    // });
    this.selectedEnum!.items.push(emptyElement);
    this.hasChanged = true;
  }

  updateSchemaElement(value: any, index: number, fieldName: string) {
    this.selectedEnum!.items[index][fieldName] = value;
    this.hasChanged = true;
  }

  getLabel(item: any) {
    return item.label || item.name || item.id;
  }

  getExternalLabel(row: any, column: EnumDescriptorColumn): string {
    const id = row[column.name];
    if (!column.externalModel) {
      return id;
    }

    const external: any = this.enums[column.externalModel];
    if (!external) {
      return id;
    }

    const externalItem = external.find((item: any) => item.id === id);
    if (!externalItem) {
      return id;
    }

    return this.getLabel(externalItem);
  }

  deleteSchemaElement(index: number) {
    // const firstHalf = this.selectedSchemaData.slice(0, index);
    // const secondHalf = this.selectedSchemaData.slice(index + 1);
    // this.selectedSchemaData = firstHalf.concat(secondHalf);
    this.hasChanged = true;

    this.fixPage();
  }

  async saveSchema() {
    if (!this.selectedEnum) {
      return;
    }

    try {
      await this.enumsService.updateSchema({
        items: this.selectedEnum.items,
        model: this.selectedEnum.model,
      });
      this.hasChanged = false;
    } catch (error) {
      console.error(error);
      this.snackbar.open('Valami hiba történt mentéskor', 'OK', {
        duration: 5000,
      });
    }
  }

  getPaginatedSchema() {
    if (!this.selectedEnum) {
      return [];
    }
    return this.selectedEnum.items.slice(this.page * this.pageSize, (this.page + 1) * this.pageSize);
  }

  prevPage() {
    if (this.page > 0) {
      --this.page;
    }
  }

  nextPage() {
    if (!this.selectedEnum) {
      return;
    }
    if (this.page * this.pageSize + this.pageSize < this.selectedEnum.items.length) {
      ++this.page;
    }
  }

  setPageSize(size: number) {
    this.pageSize = size;

    this.fixPage();
  }

  min(val1: number, val2: number) {
    return Math.min(val1, val2);
  }

  downloadselectedEnum() {
    if (!this.selectedEnum) {
      return;
    }

    const file = new Blob(
      [JSON.stringify(this.selectedEnum)],
      { type: 'application/json' },
    );
    const a = document.createElement("a");
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = this.selectedEnum.label;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  async discardChanges() {
    if (!this.selectedEnum) {
      return;
    }

    await this.selectEnum({
      label: this.selectedEnum.label,
      model: this.selectedEnum.model,
    });

    this.hasChanged = false;
  }

  editArray(schemaRow: any, fieldName: string) {
    const dialog = this.dialog.open(SchemaArrayFieldEditorDialogComponent, {
      data: {
        schemaRow,
        fieldName,
      },
    });

    dialog.afterClosed().pipe(first()).subscribe({
      next: result => {
        if (result) {
          schemaRow[fieldName] = result;
          this.hasChanged = true;
        }
      },
    });
  }

  getArraySummary(schemaRow: any, fieldName: string): string {
    const array = schemaRow[fieldName] as string[];

    if (array.length === 0) {
      return 'Üres a tömb';
    }

    if (array.length === 1) {
      return `"${array[0] || '<Üres elem>'}"`;
    }

    return `"${array[0] || '<Üres elem>'}" + ${array.length - 1}`;
  }

  async importSchema(files: FileList) {
    if (!this.selectedEnum || files.length === 0) {
      return;
    }
    const file = files.item(0) as File;
    try {
      const text = await file.text();

      const content = JSON.parse(text) as Schema<unknown>;
      const schema = this.getSchemaOfSelectedEnum();
      const validated = schema.parse(content);
      this.selectedEnum.items = validated.items;
      this.hasChanged = true;
      this.snackbar.open('Sikeres import!', 'OK', {
        duration: 5000,
      });
    } catch (error) {
      console.error(error);
      this.snackbar.open('Sikertelen import!', 'OK', { duration: 5000 });
    }
  }

  private fixPage() {
    if (!this.selectedEnum) {
      this.page = 0;
      return;
    }
    while (this.page > 0 && this.page * this.pageSize >= this.selectedEnum.items.length) {
      --this.page;
    }
  }

  private getSchemaOfSelectedEnum(): z.ZodSchema {
    const itemSchema = z.object(this.selectedEnum!.descriptor.columns.reduce((zodObject, column) => {
      let columnSchema: z.ZodSchema;
      switch (column.type) {
        case 'array': columnSchema = z.array(z.string()); break;
        case 'boolean': columnSchema = z.boolean(); break;
        case 'enum': columnSchema = z.enum(column.values as any); break;
        case 'external':
        case 'int': columnSchema = z.number().int(); break;
        case 'string': columnSchema = z.string(); break;
      }

      return {
        ...zodObject,
        [column.name]: columnSchema,
      };
    }, {} as z.ZodRawShape));

    return z.object({
      label: z.string(),
      model: z.string(),
      descriptor: z.any(),
      items: z.array(itemSchema),
    });
  }
}
