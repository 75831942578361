import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkflowEmailTestComponent } from './workflow-email-test.component';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderModule } from '@appkit4/angular-components/header';
import { ButtonModule } from '@appkit4/angular-components/button';
import { AvatarModule } from '@appkit4/angular-components/avatar';
import { PayeeInfoModule } from 'src/app/shared/payee-info/payee-info.module';
import { CheckboxModule } from '@appkit4/angular-components/checkbox';



@NgModule({
  declarations: [
    WorkflowEmailTestComponent,
  ],
  imports: [
    CommonModule,
    PayeeFormFieldsModule,
    ReactiveFormsModule,
    HeaderModule,
    ButtonModule,
    AvatarModule,
    PayeeInfoModule,
    CheckboxModule,
  ]
})
export class WorkflowEmailTestModule { }
