<header>
  <ap-header [type]="'solid'">
    <ng-template ngTemplate="title">Real-time Debt Management Tool</ng-template>
    <ng-template ngTemplate="options">
      <ap-button [icon]="'logout-fill'" (onClick)="logout()"></ap-button>
    </ng-template>
    <ng-template ngTemplate="user">
      <ap-avatar
        [name]="userName"
        borderWidth="0"
        diameter="40"
        [role]="'button'"
      ></ap-avatar>
    </ng-template>
  </ap-header>
</header>
<main>
  <h1>Teszt email küldése</h1>
  <form [formGroup]="formGroup" class="top-margin-30">
    <payee-info width="widest">
      <div>Bármelyik létező ügy azonosítója, pl. P1001.</div>
      <div>Ennek az ügynek az adataival fog az email feltöltődni.</div>
    </payee-info>
    <payee-form-field width="widest" color="white" class="top-margin-5">
      <span payee-label color="black">Ügyazonosító</span>
      <input payee-input formControlName="payee_case_id" />
    </payee-form-field>
    <payee-info width="widest" class="top-margin-15">
      <div>Template airtable-ből.</div>
      <div>Kiküldés előtti html encode-olja, nem kell unescape-elni.</div>
    </payee-info>
    <payee-form-field width="widest" color="white" class="top-margin-5">
      <span payee-label color="black">Template</span>
      <textarea payee-input formControlName="template"></textarea>
    </payee-form-field>
    <ap-button (onClick)="submit()"> Küldés! </ap-button>
  </form>
</main>
