<div class="wrapper">
	<div class="flex column grow1">
		<div class="flex grow1">
			<div class="flex column hcenter grow1">
				<div class="flex grow1 subtitle">{{subtitle}}</div>
				<div class="flex grow1 title">{{title}}</div>
			</div>
			<div class="flex vcenter">
				<div class="company">{{name}}</div>
				<div style="margin-left: 15px">
					<button mat-icon-button routerLink="/admin/profile">
						<img src="assets/Avatar.svg">
					</button>
				</div>
			</div>
		</div>
		<mat-divider></mat-divider>
	</div>
</div>