<div class="flex vcenter">
  <button payee-button (click)="addClaim()">Követelés hozzáadása</button>
</div>
<form
  *ngFor="let claimForm of claims.controls; let i = index"
  [formGroup]="claimForm"
>
  <div class="flex vcenter">
    <span class="data-row-title"
      >{{ i + 1 }}. követelés ({{ claimForm.value.id || "Piszkozat" }})</span
    >
    <button payee-button color="alert-red" (click)="removeClaim(i)">
      Követelés törlése
    </button>
  </div>
  <!-- <ng-container *ngIf="claimForm.value.files.length === 0">
    <payee-dropzone
      [multiple]="false"
      height="taller"
      (dropped)="claimEvidencesDropped($event, claimForm)"
      title="Tölts fel bizonyítékot a követeléshez"
      description=""
    ></payee-dropzone>
    <mat-error>Legalább egy fájl feltöltése kötelező!</mat-error>
  </ng-container> -->
  <!-- <div
    class="flex vcenter evidence-row"
    *ngFor="let fileForm of claimForm.get('files').controls; let fileIndex = index"
    [class.error]="fileForm.invalid"
  >
    <div class="evidence-index">{{ fileIndex + 1 }}.</div>
    <div class="flex column grow1">
      <div class="evidence-specification">
        Számla sorszáma: {{ fileForm.value.invoice_number || "Nincs kitöltve" }}
      </div>
      <div class="evidence-specification">
        <ng-container *ngIf="fileForm.value.invoice_date">
          Számla kelte: {{ fileForm.value.invoice_date | date:'yyyy.MM.dd.' }}
        </ng-container>
        <ng-container *ngIf="!fileForm.value.invoice_date">
          Nincs kitöltve
        </ng-container>
      </div>
      <div>
        <span>{{ fileForm.value.file_name }}</span>
        <button
          payee-mini-icon-button
          type="button"
          color="white"
          (click)="openClaimFile(fileForm.value)"
        >
          <payee-icon [name]="environment.EXTERNAL_LINK_ICON"></payee-icon>
        </button>
      </div>
    </div> -->
  <div class="flex column grow1">
    <div class="data-row-label" *ngIf="claimForm.value.invoice_number">
      Számla sorszáma: {{ claimForm.value.invoice_number }}
    </div>
    <div class="data-row-label" *ngIf="claimForm.value.invoice_issued_at">
      Számla kelte:
      {{ claimForm.value.invoice_issued_at | date : "yyyy.MM.dd." }}
    </div>
    <!-- <button
      payee-mini-icon-button
      type="button"
      color="white"
      (click)="editClaimFile(fileForm)"
    >
      <payee-icon name="edit-grey"></payee-icon>
    </button> -->
    <!-- <button
      payee-mini-icon-button
      type="button"
      color="white"
      (click)="removeEvidence(claimForm, fileIndex)"
    >
      <payee-icon [name]="environment.TRASH_ICON"></payee-icon>
    </button> -->
  </div>
  <payee-form-field width="wide">
    <span payee-label>Fizetési határidő</span>
    <payee-datepicker [formControl]="claimForm.get('dueDateAt')" [max]="max">
    </payee-datepicker>
  </payee-form-field>
  <payee-form-field width="wide">
    <input
      payee-input
      formControlName="originalAmount"
      [readOnly]="claimForm.value.id"
    />
    <span payee-label>Követelés összege</span>
  </payee-form-field>
  <!-- <payee-form-field width="wide">
    <input
      payee-input
      formControlName="explanation"
    >
    <span payee-label>Részletezés</span>
  </payee-form-field> -->
  <payee-form-field width="wide">
    <span payee-label>Követelés típusa</span>
    <payee-autocomplete
      [options]="claimTypeOptions"
      formControlName="claimType"
      [displayWith]="claimTypeDisplayedWith"
    >
    </payee-autocomplete>
  </payee-form-field>
  <payee-form-field width="wide" *ngIf="showClaimTypeSpecification(claimForm)">
    <input payee-input formControlName="claimTypeSpecification" />
    <span payee-label>Követelés típus részletezés</span>
  </payee-form-field>
</form>
