<div class="wrapper flex">
    <div class="sidebar flex column hcenter">
      <div class="intro flex grow1 column hcenter">
        <a href="https://payee.tech"><img class="logo" src="assets/login/payee-logo-white.svg" alt="logo"></a>
        <div class="text">
          <h4>Biztonságos adatkezelés!</h4>
          <p>
            Az ügyeid adatait biztonságosan, felhőalapú adatbázisban tároljuk. Nem kell aggódnod miattuk.
        </p>
        </div>
      </div>
    </div>
    <div class="content flex column">
      <div class="form-layout-box flex hcenter vcenter grow1">
        <div class="form-box flex column vcenter">
          <a href="https://payee.tech"><img class="logo" src="assets/login/payee-logo.svg" alt="logo"></a>
          <h3 class="title">Új jelszó beállítása</h3>
          <div *ngIf="passwordChanged" class="flex column vcenter">
            <img class="success-img" src="assets/login/check_with_circle.svg" *ngIf="passwordChanged">
            <p class="success-message">A jelszót sikeresen megváltoztattad</p>
          </div>
  
          <div *ngIf="!passwordChanged">
            <p class="description">
              A következő email címhez: {{email}}</p>
            <mat-form-field appearance="outline">
              <input type="password" matInput [formControl]="passwordForm" placeholder="Új jelszó megadása">
              <mat-error *ngIf="passwordForm.dirty && passwordForm.hasError('required')">A mező kitöltése kötelező</mat-error>
            </mat-form-field>
            <button
              mat-flat-button
              class="reset-button"
              (click)="setNewPassword()"
              [disabled]="passwordForm.invalid">
              Beállítás és mentés
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>