<div class="content">
  <div class="selectors-box flex column">
    <!-- USERS filter -->
    <div class="selector-row flex vcenter">
      <payee-form-field>
        <input payee-input [formControl]="userFormControl" />
        <span payee-label>Felhasználó email címe</span>
      </payee-form-field>
      <mat-progress-spinner
        *ngIf="tableLoading"
        color="accent"
        diameter="24"
        class="table-loading-spinner"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>

    <div class="selector-row flex vcenter">
      <button payee-button (click)="createUser()">Új felhasználó</button>
    </div>
  </div>

  <table
    mat-table
    custom-table
    [dataSource]="dataSource"
    (matSortChange)="sortData($event)"
    multiTemplateDataRows
    matSort
    class="mat-elevation-z1"
  >
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Felhasználó email címe
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="user_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Felhasználó neve
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.user_name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="role_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Jogosultságok</th>
      <td mat-cell *matCellDef="let element">
        <li *ngFor="let role of element.roles">
          {{ role.name }}
        </li>
        <div *ngIf="element.roles.length === 0">
          Nincs hozzárendelve jogosultság
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="clients">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Hitelezők</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex vcenter" *ngIf="element.clients.length > 0">
          <span>{{ element.clients[0].name }}</span>
          <span *ngIf="element.clients.length > 1">
            &nbsp;+ {{ element.clients.length - 1 }}
          </span>
        </div>
        <div *ngIf="element.clients.length === 0">
          Nincs hozzárendelve hitelező
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit_roles">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          payee-button
          width="shorter"
          height="short"
          (click)="editUser(element)"
        >
          Szerkesztés
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="element-row"
    ></tr>
  </table>

  <mat-paginator
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[1, 5, 10, 25, 100]"
    [length]="length"
    (page)="pageChange($event)"
    disableUnderline
    class="mat-elevation-z1"
  >
  </mat-paginator>
</div>
