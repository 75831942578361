<payee-popup title="Felhasználó szerkesztése">
  <mat-progress-spinner
    *ngIf="initLoading"
    color="accent"
    mode="indeterminate"
    diameter="100"
  ></mat-progress-spinner>
  <form [formGroup]="formGroup" *ngIf="!initLoading">
    <payee-form-field width="wide">
      <span payee-label>Felhasználó email címe</span>
      <input payee-input formControlName="email" />
      <ng-container payee-error *ngIf="email.dirty && email.invalid">
        <span *ngIf="email.hasError('required')">
          A mező kitöltése kötelező
        </span>
        <span *ngIf="email.hasError('email')">
          Létező email címet adjon meg
        </span>
        <span *ngIf="email.hasError('backend')">
          {{ email.getError("backend") }}
        </span>
      </ng-container>
    </payee-form-field>
    <payee-form-field width="wide">
      <span payee-label>Felhasználó neve</span>
      <input payee-input formControlName="name" />
      <ng-container payee-error *ngIf="name.dirty && name.invalid">
        <span *ngIf="name.hasError('required')">
          A mező kitöltése kötelező
        </span>
      </ng-container>
    </payee-form-field>
    <payee-form-field width="wide">
      <span payee-label>Felhasználó telefonszáma</span>
      <input payee-input formControlName="phone" />
      <ng-container payee-error *ngIf="phone.dirty && phone.invalid">
        <span *ngIf="phone.hasError('required')">
          A mező kitöltése kötelező
        </span>
        <span *ngIf="phone.hasError('invalidPhoneNumber')">
          Létező telefonszámot adjon meg
        </span>
      </ng-container>
    </payee-form-field>
    <div class="user-roles">
      <span>Felhasználó jogosultságai</span>
      <ap-checkbox
        *ngFor="let role of roleOptions"
        (onChange)="toggleRole($event, role.name)"
        [ngModel]="isRoleSelected(role.name)"
        [ngModelOptions]="{ standalone: true }"
      >
        <span>{{ role.name }}</span>
      </ap-checkbox>
    </div>
    <div class="user-clients">
      <div class="flex vcenter client-filter">
        <payee-form-field>
          <span payee-label>
            <div class="flex vcenter client-filter-label">
              <span>Felhasználó által elérhető hitelezők</span>
              <mat-progress-spinner
                *ngIf="clientsLoading"
                diameter="24"
                mode="indeterminate"
                color="accent"
              ></mat-progress-spinner>
            </div>
            <mat-progress-spinner
              *ngIf="initLoading"
              color="accent"
              mode="indeterminate"
              diameter="24"
            ></mat-progress-spinner>
          </span>
          <input
            payee-input
            [formControl]="clientFilter"
            placeholder="Kezdjen el gépelni..."
            [matAutocomplete]="auto"
          />
        </payee-form-field>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let client of filteredClients"
            (onSelectionChange)="selectClient(client)"
            [value]="clientFilter.value"
          >
            {{ client.name }}
          </mat-option>
        </mat-autocomplete>
        <ap-button
          btnType="text"
          icon="circle-delete-outline"
          type="button"
          class="client-clear-button"
          (onClick)="clearClientFilter()"
        ></ap-button>
      </div>
      <div class="flex vcenter client-row" *ngFor="let client of clients.value">
        <div class="client-name">{{ client.name }}</div>
        <ap-button
          btnType="text"
          icon="delete-outline"
          type="button"
          (onClick)="unselectClient(client)"
        ></ap-button>
      </div>
      <div class="flex vcenter client-row" *ngIf="clients.value.length === 0">
        Nincs hozzárendelt hitelező
      </div>
    </div>
  </form>
  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      width="wide"
      [disabled]="formGroup?.invalid"
      (click)="submit()"
    >
      Mentés
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button payee-button color="alert-red" width="wide" (click)="cancel()">
      Mégse
    </button>
  </ng-template>
</payee-popup>
