<payee-popup title="Termék szerkesztése">
  <div class="product-name flex vcenter">
    <span>Termék neve: {{ name }}</span>
  </div>
  <form [formGroup]="formGroup" class="top-margin-20">
    <payee-form-field width="wide">
      <span payee-label>Termék ára (nettó)</span>
      <input payee-input formControlName="payee_fee" />
      <span payee-suffix>{{ currency }}</span>
      <ng-container *ngIf="payee_fee.invalid && payee_fee.dirty" payee-error>
        <span *ngIf="payee_fee.hasError('required')">
          A mező kitöltése kötelező.
        </span>
        <span *ngIf="payee_fee.hasError('min')">
          Az összeg legalább 1 {{ currency }} kell legyen.
        </span>
      </ng-container>
    </payee-form-field>
  </form>
  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      width="wide"
      [disabled]="formGroup?.invalid || submitLoading"
      (click)="submit()"
    >
      Mentés
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button payee-button color="alert-red" width="wide" mat-dialog-close>
      Mégse
    </button>
  </ng-template>
</payee-popup>
