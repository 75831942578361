import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseFormComponent } from './case-form/case-form.component';
import { EditCaseDialogComponent } from './edit-case-dialog.component';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { PayeeDropzoneModule } from 'src/app/shared/payee-dropzone/payee-dropzone.module';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientFormComponent } from './client-form/client-form.component';
import { DebtorsFormComponent } from './debtors-form/debtors-form.component';
import { ClaimsFormComponent } from './claims-form/claims-form.component';
import { PayeeInputModule } from 'src/app/shared/payee-input/payee-input.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    EditCaseDialogComponent,
    CaseFormComponent,
    ClientFormComponent,
    DebtorsFormComponent,
    ClaimsFormComponent,
  ],
  imports: [
    CommonModule,
    PayeeFormFieldsModule,
    PayeeButtonsModule,
    PayeeIconModule,
    PayeeDropzoneModule,
    PayeePopupModule,
    FormsModule,
    ReactiveFormsModule,
    PayeeInputModule,
    MatCardModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    EditCaseDialogComponent,
  ],
})
export class EditCaseDialogModule { }
