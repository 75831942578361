<div class="wrapper flex">
  <div class="sidebar flex column hcenter">
    <div class="intro flex grow1 column hcenter">
      <a href="https://payee.tech"><img class="logo" src="assets/login/payee-logo-white.svg" alt="logo"></a>
      <div class="text">
        <h4>Örülünk, hogy újra itt vagy!</h4>
        <p>
            Kezeld könnyedén, gyorsan és biztonságosan kintlevőségeidet.
            Mindent összeszedtünk egy helyre Neked, hogy már ne kelljen utánajárnod.
        </p>
        <a href="https://payee.tech"><button class="learn-more-button" mat-button>Vissza a weboldalra</button></a>
      </div>
    </div>
  </div>
  <div class="content flex column">
    <div class="header">
      <a routerLink="/login">Vissza a bejelentkezéshez</a>
    </div>
    <div class="form-layout-box flex hcenter vcenter grow1">
      <div class="form-box flex column vcenter">
        <a href="https://payee.tech"><img class="logo" src="assets/login/payee-logo.svg" alt="logo"></a>
        <h3 class="title">Jelszó visszaállítása</h3>
        <div *ngIf="emailSent" class="flex column vcenter">
          <img class="success-img" src="assets/login/check_with_circle.svg" *ngIf="emailSent">
          <p class="success-message">Az emailt sikeresen elküldtük</p>
        </div>

        <div *ngIf="!emailSent">
          <p class="description">
            Kérlek, <strong>add meg azt az e-mail címet</strong>, amivel regisztráltál. 
            Erre küldünk egy e-mailt, benne egy linkkel, amivel könnyedén visszaállíthatod a jelszavadat.</p>
          <mat-form-field appearance="outline">
            <input matInput [formControl]="email" placeholder="pelda@payee.tech">
            <mat-error *ngIf="email.dirty && email.hasError('required')">A mező kitöltése kötelező</mat-error>
            <mat-error *ngIf="email.dirty && email.hasError('email')">Létező email címet adj meg!</mat-error>
            <mat-error *ngIf="email.dirty && email.hasError('notfound')">Ehhez az e-mail címhez nem tartozik fiók.</mat-error>
          </mat-form-field>
          <button
            mat-flat-button
            class="reset-button"
            (click)="sendEmail()"
            [disabled]="email.invalid">
            Jelszó visszaállítása
          </button>
        </div>
      </div>
    </div>
  </div>
</div>