import { Component } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { TitleService } from 'src/app/services/title/title.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  emailSent: boolean;
  email: FormControl;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private titleService: TitleService,
  ) {
    this.email = this.fb.control('', [Validators.required, Validators.email]);
    this.titleService.setTitle('Elfelejtett jelszó');
  }

  async sendEmail(): Promise<void> {
    if (this.email.invalid || this.emailSent) { return; }

    try {
      await this.authService.sendPasswordResetEmail(this.email.value);
      this.emailSent = true;
    } catch (error) {
      if (error.code === 'auth/invalid-email') {
        this.email.setErrors({...this.email.errors, email: true});
      }
      if (error.code === 'auth/user-not-found' || error.message === 'auth/user-not-found') {
        this.email.setErrors({...this.email.errors, notfound: true});
      }
    }
  }
}
