import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface FieldFormArray extends FormArray {
  controls: FormControl[];
}

@Component({
  selector: 'app-schema-array-field-editor-dialog',
  templateUrl: './schema-array-field-editor-dialog.component.html',
  styleUrls: ['./schema-array-field-editor-dialog.component.scss']
})
export class SchemaArrayFieldEditorDialogComponent {
  readonly array: FieldFormArray;

  constructor(
    private dialogRef: MatDialogRef<SchemaArrayFieldEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      schemaRow: any,
      fieldName: string,
    },
    private fb: FormBuilder,
  ) {
    const { schemaRow, fieldName } = data;

    this.array = this.fb.array([]) as FieldFormArray;

    const elements = schemaRow[fieldName];
    elements.forEach(this.addElement);
  }

  addElement = (str = '') => {
    const control = new FormControl(str);
    this.array.push(control);
  }

  removeElement(index: number) {
    if (index < 0 || index >= this.array.length) {
      return;
    }

    this.array.removeAt(index);
  }

  submit() {
    const elements = this.array.controls.map(control => control.value);
    this.dialogRef.close(elements);
  }
}
