import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type Product = {
  id: number;
  name: string;
  type: 'notice'
  | 'demand_letter'
  | 'hard_1'
  | 'hard_2';
  payee_fee: number;
  currency: 'HUF';
};

export type GetProductResponse = {
  products: Product[];
};

export type UpdateProductParams = {
  payee_fee: number;
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  async getProducts(): Promise<GetProductResponse> {
    const url = `${environment.baseUrl}/api/product`;
    const result = await axios.get<GetProductResponse>(url);
    return result.data;
  }

  async updateProduct(id: number, params: UpdateProductParams): Promise<GetProductResponse> {
    const url = `${environment.baseUrl}/api/product/${id}`;
    const result = await axios.post<GetProductResponse>(url, {
      ...params,
      '_method': 'PUT',
    });
    return result.data;
  }
}
